<template>
  <div class="auth-layout row align-content--center">
    <div class="flex xs12 pa-3 flex-center">
      <router-link class="py-5 flex-center" to="/">
      </router-link>
    </div>

    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-card-content>
            <h2>{{ $t("auth.recover_password") }}</h2>

            <va-separator />
            <p>{{$t('reset-password-text')}}</p>
            <div class="pa-3">
              <form @submit.prevent="onsubmit" class="login">
                <div class="row mb-2">
                  <va-input
                    class="mb-3"
                    v-model="email"
                    type="email"
                    :label="$t('auth.email|phone')"
                    :error="!!emailErrors.length"
                    :error-messages="emailErrors"
                  />
                </div>
                <div class="row justify--center">
                  <va-button type="submit" class="my-0">{{ $t('auth.reset_password') }}</va-button>
                </div>
              </form>    
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'recover-password',
  data () {
    return {
      email: '',
      emailErrors: [],
    }
  },
  methods: {
    onsubmit () {
      if (!this.email) {
        this.emailErrors = [this.$t('errors.required')]
      } else {
        this.$router.push('/')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-layout {
  min-height: 100vh;
  background: url('/img/bg.png') no-repeat left;
  background-size: cover;
  &__card {
    width: 100%;
    max-width: 600px;
  }
}
.va-input_solid .va-input__container {
    background: rgb(2 169 177 / 23%);
    box-shadow: 0px 7px 17px 0 rgb(31 38 135 / 2%);
    border-color: transparent;
}
.va-input__label{
  color:white !important
}
</style>
